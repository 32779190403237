import React from "react";
import Page from "../components/Page.jsx";

const FourOFour = () => {
  return (
    <Page>
      <p>Not found</p>
    </Page>
  );
};

export default FourOFour;
